import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Centered from '../components/block-content/centered-heading-and-content'

export default function NotFoundPage() {
  return (
    <Layout>
      <SEO title="Page Not found" />
      <Centered node={{ heading: `Page Not Found` }}>
        The page you requested does not exist.
      </Centered>
    </Layout>
  )
}
